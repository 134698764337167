<template>
    <!-- ======= Header ======= -->
    <header id="header" class="header fixed-top d-flex align-items-center">
        <div class="d-flex align-items-center justify-content-between">
            <router-link :to="{ name : 'peserta' }" class="logo d-flex align-items-center">
                <img src="/img/sukun-only.png" alt="">
                <span class="d-none d-lg-block">{{ role }}</span>
            </router-link>
            <i class="bi bi-list toggle-sidebar-btn"></i>
        </div>
        <!-- End Logo -->
        <div class="search-bar">
            <div class="form-check form-switch" @click.prevent="changePar">
                <input class="form-check-input" type="checkbox" id="flexSwitchCheckChecked" :checked="par">
                <label class="form-check-label" for="flexSwitchCheckChecked">{{ par===null?'':par?'Psikotes Terbuka':'Psikotes Tertutup' }}</label>
            </div>
        </div>
        <!-- End Search Bar -->
        <nav class="header-nav ms-auto">
            <ul class="d-flex align-items-center">
                <!-- End Messages Nav -->
                <li class="nav-item dropdown pe-3">
                    <a class="nav-link nav-profile d-flex align-items-center pe-0" href="#" data-bs-toggle="dropdown">
                        <!-- <img src="./img/profile-img.jpg" alt="Profile" class="rounded-circle"> -->
                        <i class="fa fa-user-circle" style="height:100%"></i>
                        <span class="d-none d-md-block dropdown-toggle ps-2">{{ nama }}</span>
                    </a>
                    <!-- End Profile Iamge Icon -->
                    <ul class="dropdown-menu dropdown-menu-end dropdown-menu-arrow profile">
                        <li class="dropdown-header">
                            <h6>{{ nama }}</h6>
                            <span>{{ role }}</span>
                        </li>
                        <li>
                            <hr class="dropdown-divider">
                        </li>
                        <li>
                            <router-link class="dropdown-item d-flex align-items-center" :to="{ name : 'reset_password' }">
                                <i class="bi bi-gear"></i>
                                <span>Reset Password</span>
                            </router-link>
                        </li>
                        <li>
                            <hr class="dropdown-divider">
                        </li>
                        <li>
                            <a class="dropdown-item d-flex align-items-center" href="#" @click="logout">
                                <i class="bi bi-box-arrow-right"></i>
                                <span>Sign Out</span>
                            </a>
                        </li>
                    </ul>
                    <!-- End Profile Dropdown Items -->
                </li>
                <!-- End Profile Nav -->
            </ul>
        </nav>
        <!-- End Icons Navigation -->
    </header>
    <!-- End Header -->
</template>

<script>
    import axios from 'axios'
    import Swal from 'sweetalert2'
    import { mapState } from 'vuex'

    export default{
        data(){
            return {
                role    : null,
                nama    : this.$store.getters.getNama,
            }
        },
        computed : mapState(['par']),
        methods : {
            logout(){
                window.localStorage.removeItem('vuex');
                this.$router.go(0)
                // this.$router.push({ name : 'login' })
            },
            async getPar(){
                if(this.par===null) {
                    var response = await axios.get(this.$store.getters.getServerURL+'par/biodata')
                    if(response.data.data){
                        // this.par = true
                        this.$store.dispatch('setPar',true)
                    } else {
                        // this.par = false
                        this.$store.dispatch('setPar',false)
                    }
                }
            },
            async changePar(){
                if(this.par){
                    var response = await axios.post(this.$store.getters.getServerURL+'par/set',{
                        admin_id : this.$store.getters.getId,
                        bool : false
                    })

                    if(response.data.error_code){
                        Swal.fire('Gagal!','Terjadi Kesalah','error')
                    } else {
                        Swal.fire('Berhasil!',this.par?'Berhasil Menutup Sistem':'Berhasil Membuka Sistem','success')
                        // this.par = false
                        this.$store.dispatch('setPar',false)
                    }
                } else if(this.par == null) {

                } else {
                    var response = await axios.post(this.$store.getters.getServerURL+'par/set',{
                        admin_id : this.$store.getters.getId,
                        bool : !this.par
                    })

                    if(response.data.error_code){
                        Swal.fire('Gagal!','Terjadi Kesalah','error')
                    } else {
                        Swal.fire('Berhasil!',this.par?'Berhasil Menutup Sistem':'Berhasil Membuka Sistem','success')
                        // this.par = true
                        this.$store.dispatch('setPar',true)
                    }
                }
            }
        },
        async mounted(){
            await this.getPar()
            let role = this.$store.getters.getRole
            if(role){
                this.role 			= 'KoorLap'
            } else {
                this.role 			= 'Admin'
            }
            
            /**
            * Template Name: NiceAdmin - v2.5.0
            * Template URL: https://bootstrapmade.com/nice-admin-bootstrap-admin-html-template/
            * Author: BootstrapMade.com
            * License: https://bootstrapmade.com/license/
            */
            (function() {
                "use strict";
                /**
                * Easy selector helper function
                */
                const select = (el, all = false) => {
                    el = el.trim()
                    if (all) {
                        return [...document.querySelectorAll(el)]
                    } else {
                        return document.querySelector(el)
                    }
                }

                /**
                * Easy event listener function
                */
                const on = (type, el, listener, all = false) => {
                    if (all) {
                        select(el, all).forEach(e => e.addEventListener(type, listener))
                    } else {
                        select(el, all).addEventListener(type, listener)
                    }
                }

                /**
                * Easy on scroll event listener 
                */
                const onscroll = (el, listener) => {
                    el.addEventListener('scroll', listener)
                }

                /**
                * Sidebar toggle
                */
                if (select('.toggle-sidebar-btn')) {
                    on('click', '.toggle-sidebar-btn', function(e) {
                        select('body').classList.toggle('toggle-sidebar')
                    })
                }
            })();
        }
    }
</script>