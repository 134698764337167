import { createRouter, createWebHistory } from 'vue-router'
import store from '../store'
import axios from 'axios'

import HomeView from '../views/HomeView.vue'
import LoginView from '../views/LoginView.vue'

import header from '../views/template/HeaderView.vue'
import sidebar from '../views/template/SidebarView.vue'
import footer from '../views/template/FooterView.vue'

const routes = [
    {
        path: '/login',
        name: 'login',
        beforeEnter : async (to, from) => {
            try {
                let obj = {
                    method 	: 'post',
                    url 	: store.getters.getServerURL+'admin/validate',
                    data 	: {
                        token : store.getters.getToken
                    }
                }
                var response = await axios(obj)
                console.log(response)
                if(response.data){
                    return {name : 'peserta'}
                }
            } catch(error) {
                console.error(error)
            }  
        },
        components: {
            default : LoginView
        }
    },
    {
        path        : '/monitoring',
        name        : 'monitoring',
        components  : {
           default  : { template : `<main id="main" class="main"><div class="pagetitle"><div>monitoring</div></div></main>` },
           header,
           sidebar,
           footer
        }
    },
    {
        path        : '/lock',
        name        : 'lock',
        components  : {
            default : { template : `<main id="main" class="main"><div class="pagetitle"><div>lock</div></div></main>` },
            header,
            sidebar,
            footer
        }
    },
    {
        path        : '/',
        name        : 'peserta',
        components  : {
            default : () => import('../views/PesertaView.vue'),
            header,
            sidebar,
            footer
        }
    },
    {
        path        : '/CFIT',
        name        : 'cfit',
        components  : {
            default : () => import('../views/CFITView.vue'),
            header,
            sidebar,
            footer
        }
    },
    {
        path        : '/kraepelin',
        name        : 'kraepelin',
        components  : {
            default : () => import('../views/KraepelinView.vue'),
            header,
            sidebar,
            footer
        }
    },
    {
        path        : '/papikostick',
        name        : 'papikostick',
        components  : {
            default : () => import('../views/PapikostickView.vue'),
            header,
            sidebar,
            footer
        }
    },
    {
        path        : '/admin',
        name        : 'admin',
        components  : {
            default : () => import('../views/AdminView.vue'),
            header,
            sidebar,
            footer
        }
    },
    {
        path        : '/admin-add',
        name        : 'admin_add',
        components  : {
            default : () => import('../views/AdminAddView.vue'),
            header,
            sidebar,
            footer
        }
    },
    {
        path        : '/reset-password',
        name        : 'reset_password',
        components  : {
            default : () => import('../views/UpdatePasswordView.vue'),
            header,
            sidebar,
            footer
        }
    },
    {
        path        : '/koorlap',
        name        : 'koorlap',
        components  : {
            default : () => import('../views/KoorlapView.vue'),
            header,
            sidebar,
            footer
        }
    },
    {
        path        : '/koorlap-add',
        name        : 'koorlap_add',
        components  : {
            default : () => import('../views/KoorlapAddView.vue'),
            header,
            sidebar,
            footer
        }
    }
]

const router = createRouter({
    history: createWebHistory(),
    routes
})

router.beforeEach(async (to, from, next) => {
    const token = store.getters.getToken
    if(to.name === 'login'){
        next()
    } else {
        if(!token){
            next({name : 'login'})
        } else {
            var response = await axios.post(store.getters.getServerURL+'admin/validate',{ token })
            if(response.data){
                next()
            } else {
                window.localStorage.removeItem('vuex');
                next({name : 'login'})
            }
        }
    }
})

export default router
