import { createStore } from 'vuex'
import createPersistedState from "vuex-persistedstate";

export default createStore({
    state: {
        serverURL 	: 'https://be-psikotes.sukunsigaretpsikotes.com/',
        token 		: null,
        ID 			: null,
        role 		: null,
        lokasi 		: null,
        nama 		: null,
        par         : null
    },
    getters: {
        getServerURL(state){ return state.serverURL },

		getToken(state){ return state.token },
		getId(state){ return state.ID },
		getRole(state){ return state.role },
		getLokasi(state){ return state.lokasi },
		getNama(state){ return state.nama },
        getPar(state){ return state.par },
    },
    mutations: {
        mutToken(state,payload){ state.token = payload },
		mutId(state,payload){ state.ID = payload },
		mutRole(state,payload){ state.role = payload },
		mutLokasi(state,payload){ state.lokasi = payload },
		mutNama(state,payload){ state.nama = payload },
        mutPar(state,payload){ state.par = payload }
    },
    actions: {
        setToken(context,payload){ context.commit('mutToken',payload) },
		setId(context,payload){ context.commit('mutId',payload) },
		setRole(context,payload){ context.commit('mutRole',payload) },
		setLokasi(context,payload){ context.commit('mutLokasi',payload) },
		setNama(context,payload){ context.commit('mutNama',payload) },
        setPar(context,payload){ context.commit('mutPar',payload) }
    },
    modules: {
    },
    plugins: [createPersistedState()]
})
